import 'spectrum-colorpicker/spectrum';

document.addEventListener('turbolinks:load', () => {
  const common = {
    preferredFormat: 'rgb',
    showInput: true,
    showButtons: false,
    containerClassName: 'sp-custom-container',
    replacerClassName: 'select form-control sp-custom-replacer'
  };
  if (document.querySelector('.customizations .job_posting')) {
    $('.js-color').spectrum({ ...common });
  }
  if (document.querySelector('.application_process_customizations')) {
    $('.js-color').spectrum({ allowEmpty: true, ...common });
  }
});

document.addEventListener('DOMContentLoaded', () => {
  $('#widget-edit').on('shown.bs.modal', () => {
    $('.js-color-picker').spectrum({
      showInput: true,
      showAlpha: true,
      showButtons: false,
      appendTo: '#widget-edit',
      preferredFormat: 'rgb',
      containerClassName: 'sp-custom-container',
      replacerClassName: 'select form-control sp-custom-replacer'
    });
  });
});
