import 'noty/src/noty.scss';
import 'noty/src/themes/semanticui.scss';

import Noty from 'noty';

window.Noty = Noty;

const FlashMessages = {
  hasDuplicate: (flashValue) => {
    const existingNoties = document.querySelectorAll('.noty_body');
    if (existingNoties === undefined || existingNoties === null) return false;
    if (Array.from(existingNoties).filter(el => el.innerHTML === flashValue).length) return true;
    return false;
  },
  renderFlashMessages: (json) => {
    if (!json) {
      json = document.querySelector('body').dataset.flash;
    }
    if (typeof json === 'string' || json instanceof String) {
      json = JSON.parse(json);
    }
    if (json === undefined) return;
    delete json['timedout'];
    Object.keys(json).forEach((k) => {
      if (FlashMessages.hasDuplicate(json[k])) return;
      new Noty({
        type: k === 'alert' ? 'error' : 'success',
        text: json[k],
        closeWith: ['click', 'button'],
        theme: 'semanticui',
        timeout: 3500,
        progressBar: true
      }).show();
    });
  },
  renderFlashFromResponse: (responseData) => {
    if (responseData.flash) {
      FlashMessages.renderFlashMessages(responseData.flash);
    }
  }
};

export default FlashMessages;
