import {
  BrowserDetect, isBadBrowser, getBadBrowserCookie, setBadBrowserCookie
} from '../modules/browser_detect';

document.addEventListener('turbolinks:load', () => {
  if (BrowserDetect.browser() === 'Internet Explorer') {
    $('input, textarea').placeholder();
    window.isOldBrowser = true;
    if (isBadBrowser() && getBadBrowserCookie('browser-warning') !== 'seen') {
      $('#js-browser-warning').show();
      $('#js-warning-close').on('click', () => {
        setBadBrowserCookie('browser-warning', 'seen', 1);
      });
    }
  }
});
