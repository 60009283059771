/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// list of component: https://github.com/material-components/material-web/blob/main/docs/size.md
import '@material/web/all.js';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import '../initializers/init_fontawesome';
import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import Rails from '@rails/ujs';
import Cookies from 'js-cookie';

import FlashMessages from '../utils/flash_messages';

window.renderFlashMessages = FlashMessages.renderFlashMessages;
window.renderFlashFromResponse = FlashMessages.renderFlashFromResponse;

Rails.start();

// only necessary while it is still used in pre-Rails 6 Coffeescript/JS files
window.Rails = Rails;
window.Cookies = Cookies;

window.Stimulus = Application.start();
const context = require.context('../controllers', true, /\.js$/);
window.Stimulus.load(definitionsFromContext(context));
window.Stimulus.debug = process.env.NODE_ENV === 'development';

function importAll (r) {
  r.keys().forEach(r);
}

importAll(require.context('../polyfills', true, /\.js$/));
importAll(require.context('../initializers', true, /\.js$/));
importAll(require.context('../behaviours', true, /\.js$/));
