import dragula from 'dragula';

function saveColumnReorder($target) {
  const url = $target.data('reorder-path');
  const data = $target.children().map((_, el) => $(el).data('id')).get();

  return $.ajax(url, {
    method: 'POST',
    data: {
      widget: data
    },
    dataType: 'text',
  });
}

function updateWidgetParent($element, $target) {
  return new Promise((resolve, _) => {
    const url = $target.data('move-path');
    const parentId = $target.parents('.js-column').data('id');
    return $.ajax(url, {
      method: 'POST',
      data: {
        widget_id: $element.data('id'),
        parent_id: parentId
      },
      dataType: 'text',
      success: () => {
        $element.data('parent', parentId);
        resolve($element);
      }
    });
  });
}

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.js-mm')) {
    const drake = dragula({
      isContainer: el => (el.classList.contains('js-dragula-container')),
      accepts: (_el, target, _source) => (parseInt($(target).parents('.js-column').data('id'), 10) > 0),
      moves: (_el, _source, handle) => (handle.classList.contains('js-handle')),
      revertOnSpill: true,
      direction: 'vertical'
    });

    drake.on('drag', () => {
      $('.canvas .column-widget .content').addClass('ex-over-structure');
    }).on('over', (el, container) => {
      const $c = $(container);
      $c.addClass('ex-over-container');
    }).on('out', (el, container) => {
      const $c = $(container);
      $c.removeClass('ex-over-container');
    });

    drake.on('drop', (element, target) => {
      const $target = $(target);
      const $element = $(element);
      const targetParentId = $target.parents('.js-column').data('id');
      const elementParentId = $element.data('parent');
      $('.canvas .column-widget .content').removeClass('ex-over-structure');
      if (elementParentId === targetParentId) {
        saveColumnReorder($target);
      } else {
        updateWidgetParent($element, $target).then(() => {
          saveColumnReorder($target);
        });
      }
    });
  }
});
