import u from 'umbrellajs';

const POPUP_WIDTH = 600;
const POPUP_HEIGHT = 700;

const popupWindowInCenter = (url, windowName, win, w, h) => {
  const y = win.top.outerHeight / 2 + win.top.screenY - (h / 2);
  const x = win.top.outerWidth / 2 + win.top.screenX - (w / 2);
  const properties = 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no';
  return win.open(url, windowName, `${properties}, width=${w}, height=${h}, top=${y}, left=${x}`);
};

document.addEventListener('turbolinks:load', () => {
  const node = u('#linkedin_signup_link');
  node.on('click', () => {
    const url = node.data('url');
    const windowTitle = node.data('title');
    const authWindow = popupWindowInCenter(url, windowTitle, window, POPUP_WIDTH, POPUP_HEIGHT);
    const pollTimer = window.setInterval(() => {
      // when we have access to document then we're back on our domain
      // + check for any different URL than the starting one
      if (authWindow.document && authWindow.document.URL.indexOf(url) === -1) {
        window.location.href = authWindow.document.URL;
        window.clearInterval(pollTimer);
        authWindow.close();
      }
    }, 500);
  });
});
