import zendeskWebWidget from '../integrations/zendesk';

document.addEventListener('turbolinks:load', () => {
  // ZENDESK
  if (document.getElementById('zendesk-user-data')) {
    const userData = document.getElementById('zendesk-user-data').dataset;
    window.zendeskUserData = userData;
    zendeskWebWidget();
  }
});
