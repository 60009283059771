import u from 'umbrellajs';

function setPropertyEventNames() {
  let hidden;
  let visibilityChange;
  if (typeof document.hidden !== 'undefined') {
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }
  return { hidden, visibilityChange };
}

function pollAuthToken() {
  const node = u('.js-auth-redir[data-apply-url]');
  const url = node.data('apply-url');
  $.get('/auth_token_polling', () => {
    window.location.href = url;
  });
}

document.addEventListener('turbolinks:load', () => {
  const node = u('.js-auth-redir[data-apply-url]');
  if (node.length) {
    const { hidden, visibilityChange } = setPropertyEventNames();
    const handleVisibilityChange = () => {
      if (!document[hidden]) {
        pollAuthToken();
      }
    };
    document.addEventListener(visibilityChange, handleVisibilityChange);
  }
});
