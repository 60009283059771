import 'blueimp-file-upload/js/vendor/jquery.ui.widget';
import 'blueimp-file-upload/js/jquery.fileupload';

window.ImageUpload = {
  initUpload: () => {
    $('.js-add-file-trigger').off('click').on('click', function() {
      const labelId = $(this).data('label-id');
      $('label[for="' + labelId + '"]').click();
    });

    const commonRemoval = ($fileContainer) => {
      const $imgPreview = $fileContainer.find('.image-preview');
      $fileContainer.find('.image-container').addClass('placeholded');
      $imgPreview.attr('src', $imgPreview.data('fallback-url'));
    };

    $('.js-fileupload').each(function() {
      $(this).fileupload({
        url: $(this).data('upload-url'),
        paramName: 'file',
        type: 'PATCH',
        formData: () => {
          const token = $('meta[name="csrf-token"]').attr('content');
          return { authenticity_token: `AUTH_TOKEN=${token}` };
        },
        orientation: {
          imageOrientation: false,
          disableImageResize: true
        }
      }).on('fileuploadadd', function() {
        $(this).find('.js-error-container').addClass('d-none');
        $(this).find('.js-spinner').removeClass('d-none');
      }).on('fileuploaddone', function(e, data) {
        $(this).find('.js-remove-file').removeClass('d-none');
        $(this).find('.js-remove-checkbox').val(false);
        $(this).find('.js-remove-checkbox').prop('checked', false);
        const response = JSON.parse(data.result)[0];
        const cache = response.image_cache;
        const previewUrl = response.thumbnail_url;
        $(this).find('.js-file-cache').val(cache);
        $(this).find('.image-preview').attr('src', previewUrl);
        $(this).find('.image-container').removeClass('placeholded');
        const container = $(this).find('.js-error-container')[0] || $(this).siblings('.js-error-container')[0];
        const $container = $(container);
        $container.addClass('d-none');
      }).on('fileuploadfail', function (e, data) {
        const container = $(this).find('.js-error-container')[0] || $(this).siblings('.js-error-container')[0];
        const $container = $(container);
        $container.find('.js-error-message').html(data.jqXHR.responseJSON[0].error);
        $container.removeClass('d-none');
      }).on('fileuploadalways', function () {
        $(this).find('.js-spinner').addClass('d-none');
      }).on('fileuploaddestroy', function () {
        commonRemoval($('.js-fileupload'));
        $('.js-remove-file').addClass('d-none');
      });

      $(this).find('.js-remove-file').on('click', function() {
        const $fileContainer = $(this).parents('.js-fileupload');
        $fileContainer.find('.js-remove-checkbox').val(true);
        $fileContainer.find('.js-remove-checkbox').prop('checked', true);
        commonRemoval($fileContainer);
        $(this).addClass('d-none');
        if ($('.js-remote-url').length) {
          $('.js-remote-url').val('');
        }
      });
    });
  }
};

document.addEventListener('turbolinks:load', () => {
  window.ImageUpload.initUpload();
});

document.addEventListener('DOMContentLoaded', () => {
  $('#widget-edit').on('shown.bs.modal', () => {
    window.ImageUpload.initUpload();
  });
});
