import Chart from 'chart.js';

const times = x => (f) => {
  if (x > 0) {
    f();
    times(x - 1)(f);
  }
};

const filterLegendLabels = (item, data) => {
  const index = data.labels.findIndex(el => el === item.text);
  if (data.datasets[0].data[index] === '0') {
    item.hidden = true;
    return false;
  }
  return item;
};

const dynamicColors = (transparency) => {
  const r = Math.floor(Math.random() * 255);
  const g = Math.floor(Math.random() * 255);
  const b = Math.floor(Math.random() * 255);
  if (transparency) {
    return `rgba(${r},${g},${b},0.3)`;
  }
  return `rgb(${r},${g},${b})`;
};

window.chartColors = {
  red: { regular: 'rgb(255,99,132)', transparent: 'rgba(255,99,132,0.1)' },
  blue: { regular: 'rgb(54,162,235)', transparent: 'rgba(54,162,235,0.2)' },
  green: { regular: 'rgb(75,192,192)', transparent: 'rgba(75,192,192,0.5)' }
};

const specificColors = (color, transparency) => {
  const transparencyKey = transparency ? 'transparent' : 'regular';
  return window.chartColors[color][transparencyKey];
};

const poolColors = (arrayLength, transparency = false) => {
  const colors = [];
  times(arrayLength)(() => colors.push(dynamicColors(transparency)));
  return colors;
};

function displayPieChart(canvas) {
  const keys = canvas.dataset.keys.split(',');
  const values = canvas.dataset.values.split(',');
  /* eslint-disable no-new */
  new Chart(canvas.getContext('2d'), {
    type: 'pie',
    responsive: true,
    maintainAspectRatio: false,
    data: {
      labels: keys,
      datasets: [{
        data: values,
        backgroundColor: poolColors(keys.length)
      }]
    },
    options: {
      legend: {
        labels: {
          filter: filterLegendLabels
        }
      }
    }
  });
}

const repaintCharts = () => {
  const pies = document.getElementsByClassName('data-pie-chart');
  Array.from(pies).forEach(el => displayPieChart(el));
};

document.addEventListener('turbolinks:load', () => {
  if ($('.statistics .statistics-container').length) {
    repaintCharts();
  }
});

export { specificColors, poolColors, filterLegendLabels };
