document.addEventListener('turbolinks:load', () => {
   const func = (e) => {
    e.preventDefault();
    const { target } = e;
    document.querySelectorAll('a[role="tab"]').forEach((tab) => {
      tab.classList.remove('active');
      tab.removeAttribute('aria-selected');
    });
    target.classList.add('active');
    target.setAttribute('aria-selected', true);
    document.querySelectorAll('[role="tabpanel"]').forEach((tabpanel) => {
      tabpanel.classList.remove('active');
    });
    const controls = target.getAttribute('aria-controls');
    document.querySelector(`#${controls}`).classList.add('active');
  };
  document.querySelectorAll('[data-tabs="tabs"] a[role="tab"]').forEach((tab) => {
    tab.addEventListener('click', func);
  });

  const errorField = document.querySelector('.recruiters_registrations .control-group.error');
  if (errorField !== null && errorField !== undefined) {
    const paneId = errorField.closest('.tab-pane').id;
    document.getElementById(`${paneId}-tab`).click();
  }
});
