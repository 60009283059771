import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'userIdInput', 'orderInput', 'orderDiv', 'userNameField', 'userEmailField', 'userImageField', 'signerTypeSelect',
    'userDetails', 'userDetailIcons', 'userImage', 'signerSelectContainer', 'signerTypeSelectContainer',
    'userPhoneInput', 'userCvrInput', 'externalFullNameInput', 'externalEmailInput', 'dragIcon',
    'buttonsGroup', 'setButton', 'container', 'signerDescriptionField', 'recruiterWorkflowInput', 'recruiterWorkflowSelect'
  ];

  static values = {
    data: Array,
    workflowData: Array,
    defaultPhotoPath: String,
    internalSignerText: String,
    externalSignerText: String
  }

  static classes = ['hidden'];

  connect () {
    if (this.element.classList.contains('editable')) {
      this.orderDivTarget.classList.add(this.hiddenClass);
    } else {
      this.orderDivTarget.classList.remove(this.hiddenClass);
    }
    this.recountOrder();
  }

  // ACTIONS

  signerTypeSelected(e) {
    this.setButtonTarget.setAttribute('disabled', 'disabled');
    const signerContainer = this.containerTarget.querySelector('.signer-box');
    const signerType = e.currentTarget.value;
    if (signerType === 'external') {
      signerContainer.classList.add('green-box');
      this.showExternalInputs();
    } else if (signerType === 'internal') {
      signerContainer.classList.remove('green-box');
      this.showSignerSelect();
    }
  }

  signerSelected(e) {
    const userId = e.currentTarget.value;
    if (userId && userId.trim() !== '') {
      this.setButtonTarget.removeAttribute('disabled');
    } else {
      this.setButtonTarget.setAttribute('disabled', 'disabled');
    }
  }

  // PRIVATE

  showExternalInputs() {
    this.signerSelectContainerTarget.classList.add(this.hiddenClass);
    this.externalFullNameInputTarget.classList.remove(this.hiddenClass);
    this.externalEmailInputTarget.classList.remove(this.hiddenClass);
    this.recruiterWorkflowInputTarget.classList.remove(this.hiddenClass);
    this.changeWorkflowSelect();
  }

  showSignerSelect() {
    this.externalFullNameInputTarget.classList.add(this.hiddenClass);
    this.externalEmailInputTarget.classList.add(this.hiddenClass);
    if (this.hasUserPhoneInputTarget) {
      this.userPhoneInputTarget.classList.add(this.hiddenClass);
    }
    if (this.hasUserCvrInputTarget) {
      this.userCvrInputTarget.classList.add(this.hiddenClass);
    }
    this.signerSelectContainerTarget.classList.remove(this.hiddenClass);
    this.recruiterWorkflowInputTarget.classList.remove(this.hiddenClass);
  }

  setUserId(userId) {
    this.userIdInputTarget.value = userId;
  }

  updateFullDataAndShowSigner(userId) {
    const fullData = this.dataValue.map(o => JSON.parse(o));
    const selectedUserData = fullData.find(e => e.id === parseInt(userId, 10));
    this.showSigner(selectedUserData);
  }

  showSigner(data) {
    this.fillSignerData(data);
    this.swapSelectToForm();
  }

  fillSignerData(data) {
    this.userNameFieldTarget.innerHTML = data.name;
    this.userEmailFieldTarget.innerHTML = data.email;
    const signerType = this.signerTypeSelectTarget.value;
    const selectedWorkflowId = parseInt(this.recruiterWorkflowSelectTarget.value);
    const workflow = this.workflowDataValue.find((workflow) => workflow.id === selectedWorkflowId);
    if (signerType === 'external') {
      this.signerDescriptionFieldTarget.innerHTML = this.externalSignerTextValue + ' (Visma Addo, ' + workflow.name + ')';
    } else {
      this.signerDescriptionFieldTarget.innerHTML = this.internalSignerTextValue + ' (Visma Addo, ' + workflow.name + ')';
    }
    this.userImageFieldTarget.src = data.photo;
    if (this.hasUserPhoneInputTarget) this.userPhoneInputTarget.querySelector('input').value = data.phone;
    if (this.hasUserCvrInputTarget) this.userCvrInputTarget.querySelector('input').value = data.cvr;
  }

  swapSelectToForm() {
    this.signerSelectContainerTarget.classList.add(this.hiddenClass);
    this.signerTypeSelectContainerTarget.classList.add(this.hiddenClass);
    this.externalFullNameInputTarget.classList.add(this.hiddenClass);
    this.externalEmailInputTarget.classList.add(this.hiddenClass);
    this.recruiterWorkflowInputTarget.classList.add(this.hiddenClass);
    this.buttonsGroupTarget.classList.add(this.hiddenClass);
    this.element.classList.remove('editable');
    this.userDetailsTarget.classList.remove(this.hiddenClass);
    this.userDetailIconsTarget.classList.remove(this.hiddenClass);
    this.userImageTarget.classList.remove(this.hiddenClass);
    this.dragIconTarget.classList.remove(this.hiddenClass);
    this.orderDivTarget.classList.remove(this.hiddenClass);
    if (this.hasUserPhoneInputTarget) {
      this.userPhoneInputTarget.classList.add(this.hiddenClass);
    }
    if (this.hasUserCvrInputTarget) {
      this.userCvrInputTarget.classList.add(this.hiddenClass);
    }
    this.recountOrder();
  }

  changeWorkflowSelect() {
    const signerType = this.signerTypeSelectTarget.value;
    if (signerType === 'internal') {
      return;
    }

    const selectedWorkflowId = parseInt(this.recruiterWorkflowSelectTarget.value);
    const workflow = this.workflowDataValue.find((workflow) => workflow.id === selectedWorkflowId);

    if (workflow.cvr_presence) {
      this.userCvrInputTarget.classList.remove(this.hiddenClass);
      this.userCvrInputTarget.style.paddingLeft = '0';
      this.userCvrInputTarget.style.paddingRight = '33px';
    } else {
      this.userCvrInputTarget.classList.add(this.hiddenClass);
    }

    if (workflow.phone_presence) {
      this.userPhoneInputTarget.classList.remove(this.hiddenClass);
    } else {
      this.userPhoneInputTarget.classList.add(this.hiddenClass);
    }

    if (workflow.phone_presence && workflow.cvr_presence) {
      this.userCvrInputTarget.style.paddingLeft = '33px';
      this.userCvrInputTarget.style.paddingRight = '0';
    }
  }

  cancel(event) {
    event.preventDefault();
    if (this.userNameFieldTarget.innerHTML.trim() !== '') {
      this.swapSelectToForm();
    } else{
      this.containerTarget.remove();
    }
  }

  setSigner(event) {
    event.preventDefault();
    const signerType = this.signerTypeSelectTarget.value;
    if (signerType === 'external') {
      this.showExternalSigner();
    } else if (signerType === 'internal') {
      const userId = this.signerSelectContainerTarget.querySelector('select').value;
      this.setUserId(userId);
      this.updateFullDataAndShowSigner(userId);
    }
  }

  showEditForm() {
    this.signerTypeSelectContainerTarget.classList.remove(this.hiddenClass);
    this.buttonsGroupTarget.classList.remove(this.hiddenClass);
    this.element.classList.add('editable');
    this.userDetailsTarget.classList.add(this.hiddenClass);
    this.userDetailIconsTarget.classList.add(this.hiddenClass);
    this.userImageTarget.classList.add(this.hiddenClass);
    this.dragIconTarget.classList.add(this.hiddenClass);
    this.orderDivTarget.classList.add(this.hiddenClass);

    const signerType = this.signerTypeSelectTarget.value;
    if (signerType === 'external') {
      this.showExternalInputs();
    } else if (signerType === 'internal') {
      this.signerSelectContainerTarget.classList.remove(this.hiddenClass);
      this.recruiterWorkflowInputTarget.classList.remove(this.hiddenClass);
    }
  }

  externalSignerInput() {
    const fullNameValue = this.externalFullNameInputTarget.querySelector('input').value;
    const emailValue = this.externalEmailInputTarget.querySelector('input').value;
    if (fullNameValue && fullNameValue.trim() !== "" && emailValue && emailValue.trim() !== "") {
      this.setButtonTarget.removeAttribute('disabled');
    } else {
      this.setButtonTarget.setAttribute('disabled', 'disabled');
    }
  }

  showExternalSigner() {
    this.userNameFieldTarget.innerHTML = this.externalFullNameInputTarget.querySelector('input').value.trim();
    this.userEmailFieldTarget.innerHTML = this.externalEmailInputTarget.querySelector('input').value.trim();
    this.userImageFieldTarget.src = this.defaultPhotoPathValue;
    const selectedWorkflowId = parseInt(this.recruiterWorkflowSelectTarget.value);
    const workflow = this.workflowDataValue.find((workflow) => workflow.id === selectedWorkflowId);
    this.signerDescriptionFieldTarget.innerHTML = this.externalSignerTextValue + ' (Visma Addo, ' + workflow.name + ')';
    this.swapSelectToForm();
  }

  checkboxSameSignerClicked(e) {
    if (e.target.checked) {
      $('button[name="add_signer_button"]').hide();
      $('.additional-signer').hide();
    } else {
      $('button[name="add_signer_button"]').show();
      $('.additional-signer').show();
    }
  }

  checkboxSameContractClicked(e) {
    if (e.target.checked) {
      localStorage.setItem('singleContract', true);
      window.frEditors.forEach(function(editor) {
        editor.html.set('')
      });
      $('.card-body:not(:first)').hide();
    } else {
      localStorage.setItem('singleContract', false);
      window.frEditors.forEach(function(editor) {
        editor.html.set('')
      });
      $('.card-body:not(:first)').show();
    }
  }

  recountOrder() {
    let count = 0;
    const containerDivsSet = new Set();

    document.querySelectorAll('.contract-signers').forEach(signersDiv => {
      const signerContainers = signersDiv.querySelectorAll('div.signer-container');
      signerContainers.forEach(container => containerDivsSet.add(container));
    });

    const containerDivs = Array.from(containerDivsSet);

    containerDivs.forEach((container) => {
      const destroyInput = container.querySelector('input[data-nested-form-target="destroyInput"]');

      if (!destroyInput || destroyInput.value !== "1") {
        container.querySelector('.contract-order-input').value = ++count;
        container.querySelector('.contract-order').innerHTML = count;
      }
    });
  }

  removeSortableTarget() {
    this.containerTarget.removeAttribute('data-sortable-target');
  }
}
