const zendeskWebWidget = function() {
  window.zEmbed = window.zE = null;
  $('body iframe[src="javascript:false"]').remove();

  (function (url, host) {
    var queue = [],
    dom,
    doc,
    where,
    iframe = document.createElement('iframe'),
    iWin,
    iDoc;

    window.zEmbed = function () {
      queue.push(arguments);
    };

    window.zE = window.zE || window.zEmbed;

    iframe.src = 'javascript:false';
    iframe.title = ''; iframe.role = 'presentation';  // a11y
    (iframe.frameElement || iframe).style.cssText = 'display: none';
    where = document.getElementsByTagName('script');
    where = where[where.length - 1];
    where.parentNode.insertBefore(iframe, where);

    iWin = iframe.contentWindow;
    iDoc = iWin.document;

    try {
      doc = iDoc;
    } catch (e) {
      dom = document.domain;
      iframe.src = 'javascript:var d=document.open();d.domain="' + dom + '";void(0);';
      doc = iDoc;
    }
    doc.open()._l = function () {
      var js = this.createElement('script');
      if (dom) this.domain = dom;
      js.id = 'js-iframe-async';
      js.src = url;
      this.t = +new Date();
      this.zendeskHost = host;
      this.zEQueue = queue;
      this.body.appendChild(js);
    };
    doc.write('<body onload="document._l();">');
    doc.close();

    const userData = window.zendeskUserData;
    zE(function() {
      zE.setLocale(userData.locale);
      if (userData.name === undefined && userData.email === undefined) return;
      zE.identify({
        name: userData.name,
        email: userData.email
      });
    });

    window.zESettings = {
      webWidget: {
        chat: {
          suppress: true // disable chat widget
        },
        helpCenter: {
          suppress: true // disable help center widget
        }
      }
    };
  }('https://assets.zendesk.com/embeddable_framework/main.js', 'elvium.zendesk.com'));
};

export default zendeskWebWidget;
